<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="tableStatus==0?'评论审核':'评论查看'" width="600px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="id" hidden>
        <el-input v-model="form.id" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="回复Id" hidden>
        <el-input v-model="form.upId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="上级用户id" hidden>
        <el-input v-model="form.upUserId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="上级用户昵称" hidden>
        <el-input v-model="form.upUserName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="社区ID" hidden>
        <el-input v-model="form.siteId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号MT4账户Id" hidden>
        <el-input v-model="form.signMt4Id" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号用户Id" hidden>
        <el-input v-model="form.signUserId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号用户昵称" hidden>
        <el-input v-model="form.signUserName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="评论人用户Id" hidden>
        <el-input v-model="form.userId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="用户昵称" hidden>
        <el-input v-model="form.userName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="评论内容">
        <el-input type="textarea" autosize disabled v-model="form.content" style="width: 450px;"/>
      </el-form-item>
      <el-form-item label="点赞数" hidden>
        <el-input v-model="form.likeSum" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="是否订阅" hidden>
        <el-input v-model="form.isOrder" style="width: 370px;"/>
      </el-form-item>
      <el-form-item v-if="tableStatus==0" label="审核状态" prop="status">
        <el-radio v-model="form.status" label=1>通过</el-radio>
        <el-radio v-model="form.status" label=2>拒绝</el-radio>
      </el-form-item>
    </el-form>
    <div v-if="tableStatus==0" slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit()">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/srCommunityComment'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    tableStatus: {
      type: Number,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        upId: '',
        upUserId: '',
        upUserName: '',
        siteId: '',
        signMt4Id: '',
        signUserId: '',
        signUserName: '',
        userId: '',
        userName: '',
        content: '',
        likeSum: '',
        isOrder: '',
        status: '',
      },
      rules: {
        status: [
          { required: true, message: '请选择审核结果', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      this.doEdit()
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '审核成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        upId: '',
        upUserId: '',
        upUserName: '',
        siteId: '',
        signMt4Id: '',
        signUserId: '',
        signUserName: '',
        userId: '',
        userName: '',
        content: '',
        likeSum: '',
        isOrder: '',
        status: '',
      }
    }
  }
}
</script>

<style scoped>

</style>
