<template>
  <div class="app-container">
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
     <el-tab-pane v-if="checkPermission(['ADMIN','COMMENT_ALL','COMMENT_SELECT'])" label="全部列表" name="one">
        <tableView :refresh="refresh1" :tableStatus='-1' :visCheck='false'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','COMMENT_ALL','COMMENT_CHECK'])" label="待审核" name="two">
        <tableView :refresh="refresh2" :tableStatus='0' :visCheck='false'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','COMMENT_ALL','COMMENT_SELECT','COMMENT_CHECK'])" label="审核通过" name="three">
        <tableView :refresh="refresh3" :tableStatus='1' :visCheck='false'/>
      </el-tab-pane>
      <el-tab-pane v-if="checkPermission(['ADMIN','COMMENT_ALL','COMMENT_SELECT','COMMENT_CHECK'])" label="审核拒绝" name="four">
        <tableView :refresh="refresh4" :tableStatus='2' :visCheck='false'/>
      </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import tableView from '@/components/community/comment/table'
import checkPermission from '@/utils/permission'
export default {
  name:'comment',
  components: { tableView },
  data(){
    return {
      activeName:'one',refresh1:false,refresh2:false,refresh3:false,refresh4:false
    }
  },
  methods:{
    checkPermission,
    handleClick(tab, event) {
        switch(tab.name){
          case 'one':
            this.refresh1=!this.refresh1
          break;
          case 'two':
            this.refresh2=!this.refresh2
          break;
          case 'three':
            this.refresh3=!this.refresh3
          break;
          case 'four':
            this.refresh4=!this.refresh4
          break;
        }
      }
  }
}
</script>

<style scoped>

</style>
