<template>
  <div class="app-container">
    <eHeader :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" border style="width: 100%;">
      <el-table-column prop="id" label="ID" width="60"/>
      <el-table-column prop="siteName" label="所属社区"/>
      <el-table-column prop="signMt4Id" label="被评论MT4" width="100"/>
      <el-table-column prop="signUserName" label="被评论用户"/>
      <el-table-column prop="userName" label="评论用户"/>
      <el-table-column prop="isOrder" label="评论者身份">
         <template slot-scope="scope">
          <span>{{scope.row.isOrder==1?'订阅者':'未订阅' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="评论内容">
        <template slot-scope="scope">
          <span>{{scope.row.content.substr(0,10)+'...'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <span>{{scope.row.status=='0'?'待审核':scope.row.status=='1'?'审核通过':'审核拒绝' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="评论时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','COMMENT_ALL','COMMENT_SELECT','COMMENT_EDIT'])" :data="scope.row" :sup_this="sup_this" :tableStatus="tableStatus"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/community/srCommunityComment'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/comment/header'
import edit from '@/components/community/comment/edit'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  props:{
    tableStatus: {
      type: Number,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      delLoading: false, sup_this: this
    }
  },
  watch:{
    refresh( newData,oldData){
        this.init()
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'community/crm/srCommunityComment'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const type = query.type
      const value = query.value
      if(this.tableStatus=='-1'){
        if(status!=null && status!=''){
          this.params['status'] = status
        }
      }else {
        this.params['status'] = this.tableStatus
      }
      if (type && value) { this.params[type] = value }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
