<template>
  <div>
    <el-button v-if="this.tableStatus==0" size="mini" type="success" @click="to">审核</el-button>
    <el-button v-if="this.tableStatus!=0" size="mini" type="success" @click="to">查看</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false" :tableStatus="tableStatus"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    tableStatus: {
      type: Number,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        upId: this.data.upId,
        upUserId: this.data.upUserId,
        upUserName: this.data.upUserName,
        siteId: this.data.siteId,
        signMt4Id: this.data.signMt4Id,
        signUserId: this.data.signUserId,
        signUserName: this.data.signUserName,
        userId: this.data.userId,
        userName: this.data.userName,
        content: this.data.content,
        likeSum: this.data.likeSum,
        isOrder: this.data.isOrder,
        status: this.data.status
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
